.splash-loader {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .splash-loader-container {
        width: 100vw;
        height: 100vh;
        position: absolute;
        color: white;
        left: 0;
        top: 0;

        .background-container {
            height: 100vh;
            flex: 1;

            .background {
                position: absolute;
                overflow: hidden;
                height: 100vh;
                width: 100vw;
                left: 0;
                top: 0;
                opacity: 0.3;
                background: rgb(var(--background-0));
                transform: scale(1.1);
            }
        }

        .percentage-text {
            font-size: 50px;
            margin-right: 30px;
            z-index: 99;
        }

        .loading-text-container {
            height: 100vh;
            z-index: 99;
            overflow: hidden;
            background: rgb(var(--background-0));
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .loading-text {
            font-size: 40px;
            padding-right: 18px;
        }
    }
}