html,
body,
#root {
    width: 100%;
    height: 100%;
}

body {
    background: rgb(var(--background-0));
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: none;
    margin: 0;
    overflow: -moz-scrollbars-none;
    color: rgb(var(--color-0));
}

::-webkit-scrollbar {
    width: 0 !important
}

* { cursor: default; }
a, .clickable { cursor: pointer; * { cursor: pointer; }}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.accent {
    color: rgb(var(--accent));
    font-weight: 700;
}